import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private http: HttpService) { }

  loadLinkData(linkID: string){
    return new Promise((resolve)=>{
      this.http.postHttp("/external/links",{linkID: linkID})
      .then((res)=>resolve({error: null, data: res}))
      .catch((err: any)=>resolve({error: err.error.message}))
    })
  }
}
