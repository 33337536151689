import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilesService } from 'src/app/services/files.service';

declare const window: Window;

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit {

  private linkID: string;
  public isLoading: boolean = true;
  public isError: string = null;
  public linkUrl: string;

  constructor(private route: ActivatedRoute, private fileService: FilesService) { }

  ngOnInit() {
    this.init();
  }

  private init(): void {
    this.route.paramMap.subscribe(params => {
      this.linkID = params.get("id");
      if(!this.linkID){
        this.setError("Invalid link id")
      }else{
        this.getLink();
      }
    })
  }

  private setError(error: string): void {
    this.isError = error;
    this.isLoading = false;
  }

  private async getLink() {
    const link: any = await this.fileService.loadLinkData(this.linkID);
    if(link.error){
      this.setError(link.error)
    }else{
      console.log(link.data);
      this.linkUrl = link.data.url;
      this.isLoading = false;
      this.isError = null;
    }
  }

}