import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  public getHttp(path: string){
    return new Promise((resolve,reject)=>{
      this.http.get(environment.serverless_api+path).subscribe((res: any)=>resolve(res),(err)=>reject(err))
    });
  }

  public postHttp(path: string, params: any = {}){
    return new Promise((resolve,reject)=>{
      this.http.post(environment.serverless_api+path, params).subscribe((res: any)=>resolve(res),(err)=>reject(err))
    });
  }
}
