import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class InviteService {

  constructor(private http: HttpService) { }

  loadInviteData(inviteID: string){
    return new Promise((resolve)=>{
      this.http.getHttp("/external/invites/"+inviteID)
      .then((res)=>resolve({error: null, data: res}))
      .catch((err: any)=>resolve({error: err.error.message}))
    })
  }

  saveInviteData(params: any){
    return new Promise((resolve)=>{
      this.http.postHttp("/external/invites/"+params.inviteID, params)
      .then((res)=>resolve({error: null, data: res}))
      .catch((err: any)=>resolve({error: err.error.message}))
    })
  }
}
