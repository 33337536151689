import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InviteComponent } from './pages/invite/invite.component';
import { UnsubscribeComponent } from './pages/unsubscribe/unsubscribe.component';
import { LandingComponent } from './pages/landing/landing.component';
import { FilesComponent } from './pages/files/files.component';

const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "landing"},
  { path: "landing", component: LandingComponent },
  { path: "invite/:id", component: InviteComponent },
  { path: "unsubscribe/:id", component: UnsubscribeComponent },
  { path: "files/:id", component: FilesComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
