import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class InterceptService implements HttpInterceptor {

  constructor(){}
   intercept(req: HttpRequest<any>, next: HttpHandler):   Observable<HttpEvent<any>> {
      let newHeaders = req.headers;
      newHeaders = newHeaders.append('x-api-key', environment.apiKey);
      const sameReq = req.clone({headers: newHeaders});
        return next.handle(sameReq)
   }
}
